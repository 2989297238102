@use '@angular/material' as mat;

// Import Material theme
@import "./cycle1tnecanope/theme";

// Include Material core mixin (prerequisite styles for common features used by multiple components)
@include mat.core();

// Include Material custom theme mixin (must be wrapped in a selector)
html {
    @include mat.all-component-themes($theme);
}